@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

.calculator-wrapper {
  max-width: 450px;
  margin: auto;
}

.calculator-wrapper .card-title {
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.calculator-wrapper .card {
  border-color: #e6e6e6 !important;
  border-radius: 10px;
}

.calculator-wrapper .card-header {
  background-color: transparent !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.calculator-selector a {
  text-align: center;
  display: block;
  width: 100%;
  border: none;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  padding: 14px;
  text-decoration: none;
  outline: none;
  color: #000 !important;
  transition: 0.3s ease;
}

.btn-emi {
  background-color: #faf2c3;
}

.btn-sip {
  background-color: #e3ebff;
}

.btn-swp {
  background-color: #cbffef;
}

.btn-lump {
  background-color: #fee1ff;
}

.btn-it {
  background-color: #c3f4ff;
}

.btn-emi:hover {
  background-color: #f3e9af;
}

.btn-sip:hover {
  background-color: #c6d2f1;
}

.btn-swp:hover {
  background-color: #a8eed9;
}

.btn-lump:hover {
  background-color: #f0bff1;
}

.btn-it:hover {
  background-color: #a0dbe9;
}